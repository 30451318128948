import { Component, OnInit } from '@angular/core';

import { AuthService } from './core/oauth0/oauth0.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public showLoader = true;
  public isLoggedIn = false;

  constructor(private auth: AuthService) {
  }

  public ngOnInit(): void {
    this.isLoggedIn = !!this.auth.profile;
  }

  public onActivate(): void {
    this.showLoader = false;
  }
}
