import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializer } from './app.initializer';
import { ApiModule } from './core/api/api.module';
import { AuthService } from './core/oauth0/oauth0.service';
import { AlgoliaService } from './core/services/algolia.service';
import { ScheduleService } from './core/services/schedule.service';
import { TenantsService } from './core/services/tenant.service';
import { UsersService } from './core/services/users.service';
import { NavBarComponent } from './nav-bar/nav-bar.component';

@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent,
    NavBarComponent
  ],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase.config),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapKey
    }),
    AngularFirestoreModule,
    BrowserModule,
    NgbPopoverModule,
    ApiModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthService, UsersService, TenantsService, AlgoliaService],
      multi: true
    },
    ScheduleService,
    CookieService
  ]
})
export class AppModule {
}
