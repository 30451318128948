import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Availability } from '@remodzy/types';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AuthService } from '../oauth0/oauth0.service';
import { BaseFirebaseService } from './base-firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService extends BaseFirebaseService<Availability> {
  constructor(
    auth: AuthService,
    db: AngularFirestore
  ) {
    super(auth, db, environment.firebase.collections.availability);
  }

  getAvailability(show$: any, start$: any, end$: any, team$: any) {
    return combineLatest([show$, start$, end$, team$]).pipe(
      switchMap(([show, start, end, team]) =>
        start != null && end != null && show ?
          this.db
            .collection(this.collectionName, ref => {
              let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
              query = query.where('tenantId', '==', this.auth.profile?.tenantId);
              if (start) {
                query = query.where('date', '>=', start);
              }
              if (end) {
                query = query.where('date', '<=', end);
              }
              query = query.where('teamId', '==', team === '' ? null : team);
              return query;
            })
            .stateChanges()
            .pipe(
              map((changes) => {
                return changes.map((doc) => this.mapStateFunction(doc));
              })
            )
          :
          of([])
      )
    );
  }
}
