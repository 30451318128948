import { SearchOptions, SearchResponse } from '@algolia/client-search';
import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/firestore';
import { AlgoliaQueue, CommonAlgolia, Tenant } from '@remodzy/types';
import { default as algoliaSearch, SearchClient, SearchIndex } from 'algoliasearch/lite';
import { from, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthService } from '../oauth0/oauth0.service';
import { BaseFirebaseService } from './base-firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService extends BaseFirebaseService {
  private static readonly defaultSearchParams: SearchOptions = {
    attributesToHighlight: [],
    hitsPerPage: environment.algolia.defaultPageSize,
    typoTolerance: false
  };

  private client: SearchClient | undefined;
  private region: string | undefined;
  private currentIndex: string | undefined;

  constructor(
    auth: AuthService,
    db: AngularFirestore
  ) {
    super(auth, db, environment.firebase.collections.algoliaQueue);
  }

  public initClient(tenant: Tenant, searchApiKey: string): void {
    const config = environment.algolia.config[tenant.country];
    if (!config) {
      throw new Error(`No algolia config for tenant region ${tenant.country}`);
    }
    this.region = tenant.country;
    this.client = algoliaSearch(config.appId, searchApiKey);
  }

  public search<T = any>(indexName: string, query: string, params?: SearchOptions): Observable<SearchResponse<T>> {
    const index = this.getIndex(indexName);
    if (!index) {
      throw new Error('No search index. You must call initClient first.');
    }
    const options = { ...AlgoliaService.defaultSearchParams, ...params };
    return from(index.search<T>(query, options));
  }

  public clearCache(): Observable<void> {
    if (!this.client) {
      throw new Error('You must call initClient first.');
    }
    return from(this.client.clearCache());
  }

  public getQueue$<T = CommonAlgolia>(index: string): Observable<AlgoliaQueue<T>[]> {
    this.currentIndex = index;
    return this.getLiveList$();
  }

  private getIndex(index: string): SearchIndex | null {
    if (!this.client) {
      return null;
    }
    return this.client.initIndex(index);
  }

  protected queryFunction(ref: CollectionReference): Query {
    return super.queryFunction(ref)
      .where('indexName', '==', this.currentIndex)
      .where('tenantRegion', '==', this.region);
  }
}
