import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Tenant } from '@remodzy/types';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthService } from '../oauth0/oauth0.service';
import { BaseFirebaseService } from './base-firebase.service';

@Injectable({
  providedIn: 'root'
})
export class TenantsService extends BaseFirebaseService<Tenant> {
  constructor(
    auth: AuthService,
    db: AngularFirestore
  ) {
    super(auth, db, environment.firebase.collections.tenants);
  }

  public getCurrent(): Observable<Tenant> {
    return this.get(this.auth.profile?.tenantId as string);
  }
}
