import { googleMapStyle } from './google-map.style';

export const environment = {
  production: true,
  api: 'https://api.remodzy.com',
  authConfig: {
    clientID: 'v2HKBWvEgfvsKxhrdDpUa0gB31G7h9A4',
    domain: 'auth.remodzy.com',
    callbackURL: 'https://service.remodzy.com'
  },
  algolia: {
    config: {
      au: {
        appId: 'I4JVWV9UAB'
      },
      eu: {
        appId: ''
      },
      us: {
        appId: 'QUUZ1FM10J'
      }
    } as { [key: string]: { appId: string; } },
    defaultPageSize: 5,
    indices: {
      users: 'auth_users',
      positions: 'core_positions',
      locations: 'core_locations',
      roles: 'core_roles',
      teams: 'core_teams',
      service_projects: 'service_projects'
    }
  },
  cookies: {
    appToken: 'appToken',
    firebaseToken: 'firebaseToken',
    searchToken: 'searchToken',
    expiresHours: 10,
    domain: 'remodzy.com'
  },
  firebase: {
    storage: {
      service_assets: 'service'
    },
    collections: {
      algoliaQueue: 'common_algolia_queue',
      groups: 'service_groups',
      files: 'service_files',
      positions: 'core_positions',
      locations: 'core_locations',
      projects: 'service_projects',
      tenants: 'auth_tenants',
      users: 'auth_users',
      availability: 'service_availability',
      teams: 'core_teams',
      roles: 'core_roles',
      tcCategories: 'service_tc_categories'
    },
    config: {
      apiKey: 'AIzaSyAKO2MjP0wxS-z13VRxwtojRDkyHaISSs4',
      appId: '1:866878681242:web:16a4bc40126382f1532f23',
      authDomain: 'remservice-b8ee7.firebaseapp.com',
      databaseURL: 'https://remservice-b8ee7.firebaseio.com',
      measurementId: 'G-KEH5Y6J8Y9',
      messagingSenderId: '866878681242',
      projectId: 'remservice-b8ee7',
      storageBucket: 'remservice-b8ee7.appspot.com'
    }
  },
  googleMapKey: 'AIzaSyAClPcqsWanzUWbMxPasp1Lh4K1TCkX3ZE',
  googleMap: googleMapStyle
};
