import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Auth0Client, default as createAuth0Client, IdToken, RedirectLoginResult } from '@auth0/auth0-spa-js';
import { User } from '@remodzy/types';
import { addHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mapTo, shareReplay, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private static get auth0Client$(): Observable<Auth0Client> {
    return from(createAuth0Client({
      domain: environment.authConfig.domain,
      client_id: environment.authConfig.clientID,
      redirect_uri: `${window.location.origin}`
    })).pipe(shareReplay(1));
  }

  private get getTokenSilently$(): Observable<string> {
    return AuthService.auth0Client$.pipe(switchMap(client => from(client.getTokenSilently())));
  }

  private get getIdTokenClaims$(): Observable<IdToken | undefined> {
    return AuthService.auth0Client$.pipe(switchMap(client => from(client.getIdTokenClaims())));
  }

  private get handleRedirectCallback$(): Observable<RedirectLoginResult> {
    return AuthService.auth0Client$.pipe(switchMap(client => from(client.handleRedirectCallback())));
  }

  private get isAuthenticated$(): Observable<boolean> {
    return AuthService.auth0Client$.pipe(switchMap(client => from(client.isAuthenticated())));
  }

  public idToken: string | null = null;
  public algoliaToken: string | null = null;
  public profile: User | null = null;

  private firebaseToken: string | null = null;
  private jwtHelper = new JwtHelperService();

  constructor(
    private ngFireAuth: AngularFireAuth,
    private api: ApiService,
    private cookie: CookieService
  ) {
  }

  public checkCookies(): Observable<string> {
    const appToken = this.cookie.get(environment.cookies.appToken);
    if (!appToken) {
      return of('');
    }

    if (this.jwtHelper.isTokenExpired(appToken)) {
      this.cookie.delete(environment.cookies.appToken);
      this.cookie.delete(environment.cookies.firebaseToken);
      return of('');
    }

    this.idToken = appToken;
    this.algoliaToken = this.cookie.get(environment.cookies.searchToken);

    console.log('appToken: ', this.idToken);
    console.log('algoliaToken: ', this.algoliaToken);

    return of(this.cookie.get(environment.cookies.firebaseToken))
      .pipe(
        switchMap(fbToken => {
          if (this.jwtHelper.isTokenExpired(fbToken)) {
            return this.api.core.getFireBaseToken();
          }
          return of(fbToken);
        }),
        tap(fbToken => this.firebaseToken = fbToken),
        tap(() => console.log('firebaseToken: ', this.firebaseToken)),
        tap(fbToken => this.setCookie(environment.cookies.firebaseToken, fbToken)),
        switchMap(() => this.algoliaToken ? of(this.algoliaToken) : this.api.core.getAlgoliaToken()),
        tap(algoliaToken => this.algoliaToken = algoliaToken),
        tap(algoliaToken => this.setCookie(environment.cookies.searchToken, algoliaToken)),
        switchMap(() => this.loginToFirebase()),
        map(userId => userId || '')
      );
  }

  public checkSession(): Observable<string> {
    return this.isAuthenticated$.pipe(
      switchMap(isLoggedIn => {
        if (isLoggedIn) {
          return of(isLoggedIn);
        }
        return this.getTokenSilently$;
      }),
      catchError(err => {
        const params = window.location.search;
        const isDevLogin = !environment.production && params.includes('code=') && params.includes('state=');

        if (isDevLogin) {
          console.log('DEV login');
          return this.handleRedirectCallback$;
        }

        // Go to auth page
        throw err;
      }),
      tap(() => {
        if (window.location.search.includes('code=')) {
          const path = window.location.href.replace(window.location.search, '');
          window.history.pushState(null, '', path);
        }
      }),
      switchMap(() => this.getAuthToken()),
      switchMap(() => this.api.core.getFireBaseToken()),
      tap(fbToken => this.firebaseToken = fbToken),
      tap(fbToken => this.setCookie(environment.cookies.firebaseToken, fbToken)),
      switchMap(() => this.api.core.getAlgoliaToken()),
      tap(algoliaToken => this.algoliaToken = algoliaToken),
      tap(algoliaToken => this.setCookie(environment.cookies.searchToken, algoliaToken)),
      switchMap(() => this.loginToFirebase()),
      map(userId => userId || '')
    ) as Observable<string>;
  }

  public login(): void {
    AuthService.auth0Client$.subscribe(client => {
      client.loginWithRedirect({
        redirect_uri: `${environment.authConfig.callbackURL}`
      });
    });
  }

  public logout(): void {
    AuthService.auth0Client$.subscribe(client => {
      client.logout({
        client_id: environment.authConfig.clientID,
        returnTo: `${window.location.origin}`
      });
    });
  }

  private getAuthToken(): Observable<void> {
    return this.getIdTokenClaims$.pipe(
      tap(claims => this.idToken = claims?.__raw || null),
      tap(claims => this.setCookie(environment.cookies.appToken, claims?.__raw || '')),
      mapTo(void 0)
    );
  }

  private loginToFirebase(): Observable<string | null> {
    return this.firebaseToken
      ? from(this.ngFireAuth.signInWithCustomToken(this.firebaseToken as string))
        .pipe(map(credentials => credentials.user?.uid || null))
      : of(null);
  }

  private setCookie(name: string, value: string): void {
    const expires = addHours(new Date(), environment.cookies.expiresHours);
    this.cookie.set(name, value, expires, '/', environment.cookies.domain);
  }
}
