import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { AuthService } from '../core/oauth0/oauth0.service';
import { Observable } from 'rxjs';
import { AlgoliaService } from '../core/services/algolia.service';
import { TeamSearch } from '@remodzy/types';
import { environment } from 'src/environments/environment';
import { tap, mapTo } from 'rxjs/operators';
import { BaseComponent } from '../core/services/base.component';
import { ScheduleService } from '../core/services/schedule.service';

interface TeamMenuLocation {
  location: string;
  teams: TeamSearch[];
}

@Component({
  selector: 'app-navbar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent extends BaseComponent implements OnInit  {
  collapsed = true;
  currentTeam: TeamSearch | undefined;
  public locations: TeamMenuLocation[] = [];

  constructor(
    private auth: AuthService,
    private algoliaService: AlgoliaService,
    private scheduleService: ScheduleService) {
      super();
  }

  public ngOnInit(): void {
    this.subs = this.searchTeams().subscribe();
  }

  public get fullName(): string {
    return this.auth.profile?.fullName || '';
  }

  getCurrentTeam(): void {
    const foundLocation = this.locations.find(loc => {
      return loc.teams.find(team => team.objectID === this.auth.profile?.teamId);
    });

    if (foundLocation) {
      const foundTeam = foundLocation.teams.find(team => team.objectID === this.auth.profile?.teamId);
      this.currentTeam = foundTeam;
    }
  }

  public get initials(): string {
    return this.auth.profile ?
      `${this.auth.profile.firstName.substring(0, 1)}${this.auth.profile.lastName.substring(0, 1)}` :
      '';
  }

  private searchTeams(): Observable<void> {
    return this.algoliaService
      .search<TeamSearch>(environment.algolia.indices.teams, '')
      .pipe(
        tap(res => {
          const groupedByLocation = res.hits.reduce((r, a) => {
            r[a.location] = r[a.location] || [];
            r[a.location].push(a);
            return r;
            }, Object.create(null)
          );

          this.locations = Object.keys(groupedByLocation).map((key) => {
              return { location: key, teams: groupedByLocation[key] };
          });

          this.getCurrentTeam();
          this.selectTeam(this.currentTeam);
        }),
        mapTo(void 0)
      );
  }

  selectTeam(team: TeamSearch | undefined) {
    if (this.currentTeam !== team) {
      this.currentTeam = team;
    }
    if (team) {
      this.scheduleService.switchTeam(team.firestoreId);
    }
  }
}
