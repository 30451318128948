import { of } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';

import { AuthService } from './core/oauth0/oauth0.service';
import { AlgoliaService } from './core/services/algolia.service';
import { TenantsService } from './core/services/tenant.service';
import { UsersService } from './core/services/users.service';

export function appInitializer(
  authService: AuthService,
  usersService: UsersService,
  tenantService: TenantsService,
  algolia: AlgoliaService
) {
  return (): Promise<void> => authService.checkCookies()
    .pipe(
      switchMap(userId => {
        if (userId) {
          return of(userId);
        }
        return authService.checkSession();
      }),
      switchMap(userId => usersService.getUserByUserId(userId)),
      tap(user => authService.profile = user),
      switchMap(() => tenantService.getCurrent()),
      tap(tenant => algolia.initClient(tenant, authService.algoliaToken as string)),
      mapTo(void 0),
      catchError(() => {
        // No cookies, we must login
        return of(void 0);
      })
    )
    .toPromise();
}
