import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DragHelper, EventStore, ResourceStore, ResourceTimeRangeStore } from 'bryntum-scheduler';
import { addMonths, endOfMonth, getHours, getMinutes, setHours, setMinutes, startOfMonth, addHours } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventSubType } from '../models/eventSubType.enum';
import { EventType } from '../models/eventType.enum';
import { Operation } from '../models/operation.enum';
import { ScheduleUserEvent } from '../models/scheduleEvents.enum';
import { GroupService } from './group.service';
import { ProjectService } from './project.service';
import { UsersService } from './users.service';
import { AvailabilityService } from './availability.service';
import { Project, Group } from '@remodzy/types';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  // tenant settigs
  useAvailability = true;
  showOnlyEmployee = true;
  // end tenant settings

  // project settings
  // project status / color
  colorStatus = {
    pending: 'cs_6',
    accepted: 'cs_2',
    declined: 'cs_11'
  };


  // end project settings

  // group settings

  // end group settings

  // stores
  resourceStore: any;
  resourceRangeStore: any;
  eventStore: any;
  dragHelper: any;
  schedule: any;
  // end stores

  teamFilter$ = new BehaviorSubject<string>('');
  // statusFilter$ = new BehaviorSubject<string | null>(null);
  unitShift$ = new Subject();
  isTimeSheet$ = new BehaviorSubject<boolean>(false);

  showAvailability$ = new BehaviorSubject<boolean>(true);
  showProjects$ = new BehaviorSubject<boolean>(true);
  showGroups$ = new BehaviorSubject<boolean>(true);

  viewDate: Date = new Date();
  startDate = startOfMonth(this.viewDate);
  endDate = endOfMonth(this.viewDate);

  start$ = new BehaviorSubject(this.startDate);
  end$ = new BehaviorSubject(this.endDate);

  init = true;

  columns = [
    {
      text: 'EMPLOYEES',
      field: 'name',
      htmlEncode: false,
      id: 'employee',
      width: 230,
      renderer: ({ record }: any) => {
        return `<div class="b-resource-info">
                  <div class="employee_cell">
                    <div class="profile_logo mr-3">${record ?
                        `${record.firstName.substring(0, 1)}${record.lastName.substring(0, 1)}` :
                        ''}</div>
                    <div style="opacity:0;" class="presence"></div>
                    <dl class="mb-0">
                      <dt>${record.fullName}</dt>
                      <dd class="b-resource-role">${record.positionName ? record.positionName : ''}</dd>
                    </dl>
                  </div>
                </div>`;
      },
      headerRenderer: () => {
        return `<div class="res_header">
                  <div>EMPLOYEES</div>
                  <img id="addEmployee" src="/assets/icons/addEmploee.svg" alt="add">
                </div>`;
      }
    }
  ];

  constructor(
    public userService: UsersService,
    public projectService: ProjectService,
    public groupService: GroupService,
    public availabilityService: AvailabilityService,
    private modalService: NgbModal,
    private modal: NgbModal
  ) {
    this.dragHelper = new DragHelper({
      cloneTarget: true,
      mode: 'translateXY',
      dropTargetSelector: '.b-timeline-subgrid',
      targetSelector: '.addSegment'
    });

    this.resourceStore = new ResourceStore({
      reapplyFilterOnAdd: true,
      reapplyFilterOnUpdate: true
    });

    this.eventStore = new EventStore({
      reapplyFilterOnAdd: true,
      reapplyFilterOnUpdate: true
    });

    this.resourceRangeStore = new ResourceTimeRangeStore();
  }


  public loadResources() {
    this.userService.getScheduleUsers(this.teamFilter$, this.showOnlyEmployee)
      .subscribe(states => {
        states.map(state => {
          const employee = state.object;
          if (state.operation === Operation.added) {
            this.resourceStore.add(employee);
          }

          if (state.operation === Operation.modified) {
            const found = this.resourceStore.find((record: any) => record.id === employee.id);
            if (found) {
              found.set({
                role: employee.role,
                fullName: employee.fullName,
                firstName: employee.firstName,
                lastName: employee.lastName,
                phone: employee.phone,
                email: employee.email,
                userId: employee.userId,
                tenantId: employee.tenantId,
                positionName: employee.positionName,
                positionId: employee.positionId,
                teamId: employee.teamId
              });
            } else {
              this.resourceStore.add(employee);
            }
          }
          if (state.operation === Operation.removed) {
            const found = this.resourceStore.find((record: any) => record.id === employee.id);
            if (found) {
              found.remove();
            }
          }
        });
      });
  }

  public loadAvailability() {
    this.availabilityService.getAvailability(
      this.showAvailability$,
      this.start$,
      this.end$,
      this.teamFilter$
    )
    .subscribe((states: any) => {
      states.map((state: any) => {
        const availability = state.object;
        availability.startDate = availability.date;
        availability.endDate = addHours(availability.startDate, 1);

        if (state.operation === Operation.added) {
          availability.timeRangeColor = availability.colorStyle;
          this.resourceRangeStore.add(availability);
        }

        if (state.operation === Operation.modified) {
          const found = this.resourceRangeStore.find((record: any) => record.id === availability.id);
          if (found) {
            found.set({
              name: availability.name,
              timeRangeColor: availability.colorStyle,
              startDate: availability.startDate,
              endDate: availability.endDate
            });
          } else {
            this.resourceRangeStore.add(availability);
          }
        }

        if (state.operation === Operation.removed) {
          const found = this.resourceRangeStore.find((record: any) => record.id === availability.id);
          if (found) {
            found.remove();
          }
        }
      });
    });
  }


  public loadEvents() {
    this.projectService.getScheduleProjects(
      this.start$,
      this.end$,
      this.isTimeSheet$,
      this.teamFilter$,
      this.showProjects$
    )
      .subscribe(states => {
        this.renderEvents(states, EventType.schedule, EventSubType.project);
      });

    this.groupService.getSchedulGroups(
      this.start$,
      this.end$,
      this.isTimeSheet$,
      this.teamFilter$,
      this.showGroups$
    )
      .subscribe(states => {
        this.renderEvents(states, EventType.schedule, EventSubType.group);
      });
  }


  renderEvents(states: any, type: EventType | null = null, subType: EventSubType | null = null) {
    states.map((state: any) => {
      const event = state.object;
      event.type = type;
      event.subType = subType;
      if (state.operation === Operation.added) {
        this.eventStore.add(event);
      }

      if (state.operation === Operation.modified) {
        const found = this.eventStore.find((record: any) => record.id === event.id);
        if (found) {
          found.set({
            startDate: event.startDate,
            endDate: event.endDate,
            name: event.name,
            description: event.eventdescription,
            resourceId: event.resourceId,
            teamId: event.teamId
          });
        } else {
          this.eventStore.add(event);
        }
      }
      if (state.operation === Operation.removed) {
        const found = this.eventStore.find((record: any) => record.id === event.id);
        if (found) {
          found.remove();
        }
      }
    });
  }

  filterProjects(status: string) {
    if (this.eventStore.isFiltered) {
      this.eventStore.clearFilters();
    }

    if (status) {
      if (this.eventStore.records.length > 0) {
        this.eventStore.filterBy((record: Project) => {
          return status == null ? true : record.status === status;
        });
      }
    }
  }

  setMode(mode: string) {
    this.eventStore.removeAll();
    this.isTimeSheet$.next(mode === EventType.timesheet);
  }

  showHideAvailability() {
    this.resourceRangeStore.removeAll();
  }

  moveForward() {
    if (this.eventStore.isFiltered) {
      this.eventStore.clearFilters();
    }
    this.eventStore.removeAll();
    this.resourceRangeStore.removeAll();
    this.viewDate = addMonths(this.viewDate, 1);
    this.start$.next(startOfMonth(this.viewDate));
    this.end$.next(endOfMonth(this.viewDate));
  }

  moveBack() {
    if (this.eventStore.isFiltered) {
      this.eventStore.clearFilters();
    }
    this.eventStore.removeAll();
    this.resourceRangeStore.removeAll();
    this.viewDate = addMonths(this.viewDate, -1);
    this.start$.next(startOfMonth(this.viewDate));
    this.end$.next(endOfMonth(this.viewDate));
  }

  switchTeam(teamId: string) {
    this.resourceStore.beginBatch();
    this.resourceStore.removeAll(false);
    this.resourceStore.endBatch();
    this.eventStore.removeAll();
    this.resourceRangeStore.removeAll();

    this.teamFilter$.next(teamId);
  }


  addGroup(group: Group) {
    this.groupService.create(group);
  }

  onSchedulerEvents(event: any) {
    switch (event.type) {
      case ScheduleUserEvent.aftereventdrop:
        this.eventDragged(event);
        break;
    }
  }

  updateProject(update: any) {
    const project = {
      startDate: update.newStart,
      endDate: update.newEnd,
      resourceId: update.newResourceId
    };
    this.projectService.update(update.id, project, true);
  }

  updateGroup(update: any) {
    const group = {
      startDate: update.newStart,
      endDate: update.newEnd,
      resourceId: update.newResourceId
    };
    this.groupService.update(update.id, group, true);
  }

  updateTimeCard(update: any) {
    // TODO
  }

  cloneTime(oldDate: Date, newDate: Date) {
    return setMinutes(setHours(newDate, getHours(oldDate)), getMinutes(oldDate));
  }

  eventDragged(event: any) {
    const records = event.eventRecords;
    records.forEach((record: any) => {
      const update = {
        newStart: this.cloneTime(event.context.origStart, event.context.startDate),
        newEnd: this.cloneTime(event.context.origEnd, event.context.endDate),
        newResourceId: event.context.resourceRecord.id !== event.context.newResource.id ?
          event.context.newResource.id : event.context.resourceRecord.id,
        id: record.data.id
      };
      switch (record.data.type) {
        case EventType.schedule:
          record.data.subType === EventSubType.group ?
            this.updateGroup(update) : this.updateProject(update);
          break;
        case EventType.timesheet:
          this.updateTimeCard(update);
          break;
      }
    });
  }


  getCurrentTeam() {
    return this.teamFilter$.value;
  }

  setShowAvailability(showAvailability: boolean) {
    if (this.resourceRangeStore.count > 0) {
      this.resourceRangeStore.removeAll();
    }
    this.showAvailability$.next(showAvailability);
  }

  setShowProjects(showProjects: boolean) {
    const found = this.eventStore.findByField('subType', EventSubType.project);
    if (found && found.length > 0) {
      const ids = found.map((item: any) => item.id);
      this.eventStore.remove(ids);
    }
    this.showProjects$.next(showProjects);
  }

  setShowGroups(showGroups: boolean) {
    const found = this.eventStore.findByField('subType', EventSubType.group);
    if (found && found.length > 0) {
      const ids = found.map((item: any) => item.id);
      this.eventStore.remove(ids);
    }
    this.showGroups$.next(showGroups);
  }
}
