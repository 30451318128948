import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { ApiService } from './api.service';
import { CoreService } from './core.service';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';

@NgModule({
  imports: [
    HttpClientModule,
    AngularFireDatabaseModule
  ],
  providers: [
    ApiService,
    CoreService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true }
  ]
})
export class ApiModule {
}
