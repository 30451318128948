<nav class="navbar">
  <div class="leftSide">
    <div [ngbPopover]="popApps">
      <img class="logo" src="../../assets/icons/smallLogo.svg">
    </div>
    <div [ngbPopover]="popTeam" class="team">
      <div class="team_logo">
        <img src="../../assets/icons/team.svg">
      </div>
      <div class="team_team">
        <p class="team_location mb-0">{{ currentTeam?.location }}</p>
        <p class="team_name mb-0"><strong>{{ currentTeam?.name }}</strong></p>
      </div>
      <div class="team_expand_bg">
        <img class="team_expand" src="../../assets/icons/nav_arr_down.svg">
      </div>
      <div class="divider"></div>
    </div>
    <div class="main_menu">
      <a [routerLinkActive]="['main_menu--active']" [routerLink]="['schedule']" class="main_menu_link">Schedule</a>
      <a [routerLinkActive]="['main_menu--active']" [routerLink]="['messaging']" class="main_menu_link">Messaging</a>
      <a [routerLinkActive]="['main_menu--active']" [routerLink]="['reporting']" class="main_menu_link">Reporting</a>
      <a [routerLinkActive]="['main_menu--active']" [routerLink]="['staff']" class="main_menu_link">Staff</a>
    </div>
  </div>
  <div class="rightSide">
    <div class="profile">
      <div class="profile_logo">{{ initials }}</div>
      <div>
        <p class="profile_name mb-0">{{ fullName }}</p>
      </div>
      <div class="team_expand_bg ml-2" [ngbPopover]="popContent">
        <img class="team_expand" src="../../assets/icons/nav_arr_down.svg">
      </div>
    </div>
  </div>
</nav>

<ng-template #popContent>
  <div class="profile_menu">
    <a [routerLinkActive]="['main_menu--active']" [routerLink]="['settings/profile']" class="main_menu_link">My Profile</a>
    <a [routerLinkActive]="['main_menu--active']" [routerLink]="['settings/business']" class="main_menu_link">Settings</a>
    <a [routerLinkActive]="['main_menu--active']" [routerLink]="['messaging']" class="main_menu_link">Log Out</a>
  </div>
</ng-template>

<ng-template #popApps>
  <div class="apps_menu">
    <div>
      <h5 class="mb-3">Switch to:</h5>
    </div>
    <a href="https://forms.remodzy.com" class="apps_menu_link">
      <img [ngbPopover]="popApps" class="logo" src="../../assets/icons/smallLogoForms.svg">
      <p class="mb-0">Forms</p>
    </a>
  </div>
</ng-template>

<ng-template #popTeam>
  <div class="team_menu_selector">

    <div class="row" *ngFor="let location of locations">
      <div class="col">
        <div class="team_location">
          <div class="mb-0 loc_name">{{ location.location }}</div>
          <div (click)="selectTeam(team)" *ngFor="let team of location.teams"  class="team_cont">
            <div class="mb-0 team_name">{{ team.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
