import { Injectable } from '@angular/core';

import { CoreService } from './core.service';

@Injectable()
export class ApiService {
  constructor(
    public core: CoreService
  ) {
  }
}
