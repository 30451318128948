import { AngularFirestore, CollectionReference, DocumentReference, Query } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { from, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { chunk as _chunk } from 'lodash';
import { StateOperation } from '../models/stateOperation';
import { AuthService } from '../oauth0/oauth0.service';

export interface BaseDocFields {
  createdAt?: Date | firestore.Timestamp;
  id?: string;
  tenantId?: string;
  updatedAt?: Date | firestore.Timestamp;
}

export class BaseFirebaseService<T extends BaseDocFields = any> {
  maxBatchSize = 500;
  constructor(
    protected auth: AuthService,
    protected db: AngularFirestore,
    protected collectionName: string
  ) {
  }

  public getList(): Observable<T[]> {
    return this.db.collection<T>(this.collectionName, ref => this.queryFunction(ref)).get()
      .pipe(map(querySnapshot => {
        return querySnapshot.empty ? [] : querySnapshot.docs.map(doc => this.mapFunction(doc));
      }));
  }

  public getLiveList$(): Observable<T[]> {
    return this.db.collection<T>(this.collectionName, ref => this.queryFunction(ref)).snapshotChanges()
      .pipe(map(documentChangeAction => {
        return documentChangeAction.map(docChange => this.mapFunction(docChange.payload.doc));
      }));
  }

  public get(docId: string): Observable<T> {
    return this.db.collection<T>(this.collectionName).doc<T>(docId).get()
      .pipe(map(documentSnapshot => this.mapFunction(documentSnapshot)));
  }

  public create(data: T): Observable<DocumentReference> {
    const timestamp = new Date();
    const copy = {
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    };
    delete copy.id;
    copy.tenantId = this.auth.profile?.tenantId;
    return from(this.db.collection<T>(this.collectionName).add(copy));
  }

  public update(docId: string, data: Partial<T>, merge = false): Observable<void> {
    const timestamp = new Date();
    const copy = {
      ...data,
      updatedAt: timestamp
    };
    delete copy.id;
    copy.tenantId = this.auth.profile?.tenantId;
    return from(this.db.collection<Partial<T>>(this.collectionName).doc<Partial<T>>(docId).set(copy, { merge }));
  }

  public delete(docId: string): Observable<void> {
    return from(this.db.collection(this.collectionName).doc(docId).delete());
  }

  protected queryFunction(ref: CollectionReference): Query {
    return ref.where('tenantId', '==', this.auth.profile?.tenantId);
  }

  protected mapFunction(doc: firestore.DocumentSnapshot): T {
    return {
      ...(this.mapTimestamps(doc.data()) as T),
      id: doc.id
    };
  }

  protected mapTimestamps(obj?: { [key: string]: any }): any {
    if (!obj) {
      return;
    }

    Object.keys(obj)
      .filter(key => obj[key] instanceof firestore.Timestamp)
      .forEach(key => obj[key] = (obj[key] as firestore.Timestamp).toDate());

    return obj;
  }

  protected mapStateFunction(change: any): StateOperation<T> {
    return {
      object: {
        ...(this.mapTimestamps(change.payload.doc.data()) as T),
        id: change.payload.doc.id
      },
      operation: change.type
    };
  }


  public batchSave(files: Partial<T>[], merge = false): Observable<any> {
    const timestamp = new Date();
    const chunks = _chunk<Partial<T>>(files, this.maxBatchSize);
    const collectionRef = this.db.firestore.collection(this.collectionName);
    const requests: Observable<any>[] = [];
    chunks.forEach(chunk => {
      const batch = this.db.firestore.batch();
      chunk.forEach(file => {
        const docRef = file.id ? collectionRef.doc(file.id) : collectionRef.doc();
        delete file.id;
        const copy = {
          ...file,
          updatedAt: timestamp,
          createdAt: timestamp
        };

        copy.tenantId = this.auth.profile?.tenantId;
        batch.set(docRef, copy, { merge });
      });
      requests.push(from(batch.commit()));
    });
    return forkJoin(requests);
  }
}
