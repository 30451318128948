import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '@remodzy/types';
import { default as _chunk } from 'lodash-es/chunk';
import { combineLatest, forkJoin, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { StateOperation } from '../models/stateOperation';
import { AuthService } from '../oauth0/oauth0.service';
import { BaseFirebaseService } from './base-firebase.service';

const maxBatchSize = 500;

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseFirebaseService<User> {
  constructor(
    auth: AuthService,
    db: AngularFirestore
  ) {
    super(auth, db, environment.firebase.collections.users);
  }

  public getUserByUserId(userId: string): Observable<User> {
    return this.db.collection<User>(this.collectionName, ref => {
      return ref.where('userId', '==', userId);
    }).get()
      .pipe(
        map(querySnapshot => {
          return querySnapshot.empty ? [] : querySnapshot.docs.map(doc => this.mapFunction(doc));
        }),
        map(users => users[0])
      );
  }

  public getScheduleUsers(team$: any, showManagerOnSchedule: any) {
    return combineLatest([team$]).pipe(
      switchMap(([team]) =>
        this.db
          .collection<StateOperation<User>>(this.collectionName, ref => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.where('tenantId', '==', this.auth.profile?.tenantId);
            if (!showManagerOnSchedule) {
              query = query.where('role', '==', 'employee');
            }
            query = query.where('teamId', '==', team === '' ? null : team);
            return query.orderBy('fullName');
          })
          .stateChanges()
          .pipe(
            map((changes) => {
              return changes.map((doc) => this.mapStateFunction(doc));
            })
          )
      )
    );
  }
}
