import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CoreService {
  private static readonly url = '/core';

  constructor(private http: HttpClient) {
  }

  public getFireBaseToken(): Observable<string> {
    return this.http.get<{ token: string }>(`${CoreService.url}/token`)
      .pipe(map(res => res.token));
  }

  public getAlgoliaToken(): Observable<string> {
    return this.http.get<{ token: string }>(`${CoreService.url}/search-token`)
      .pipe(map(res => res.token));
  }

  public getDBConnectionString(): Observable<any> {
    return this.http.get(`${CoreService.url}/settings/get-db-connection`);
  }

  public getAPICredentials(): Observable<any> {
    return this.http.get(`${CoreService.url}/settings/get-api-creds`);
  }

  public resetPassword(password: string): Observable<any> {
    return this.http.post(
      `${CoreService.url}/settings/reset-password`,
      { password }
    );
  }

  public validateAddress(address: string) {
    return this.http.post(
      `${CoreService.url}/maps/validate-address`,
      { address }
    );
  }
}
